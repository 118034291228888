import * as React from 'react';

import DefaultLayout from '../layout/DefaultLayout';

const NotFoundPage = () => {
  return (
    <DefaultLayout>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </DefaultLayout>
  );
};

export default NotFoundPage;
